import React from "react"
import { Link } from "gatsby"
import { getTextFromSlices } from "../utils/helpers"
import { POST_PREVIEW_TEXT_LENGTH } from "../utils/constants"
import { getPath } from "../utils/gatsby-node-helpers"

const FirstPost = ({ post, home, locale, categories }) => {
  if (!post) return null
  return (
    <div className="row align-items-center blog-teaser mb-6 mb-md-7">
      <div className="col-md-6 mb-4 mb-md-0">
        <div className="mb-2">
          <Link
            to={getPath({
              docType: "category",
              lang: locale,
              uid: post?.data?.category?.uid,
            })}
            className="text-muted"
          >
            <small className="border-bottom border-success">
              {
                categories.find(
                  c => c.uid === post.data.category.uid && c.lang === post.lang,
                )?.data.category
              }
            </small>
          </Link>
        </div>
        <h2>
          <Link
            to={getPath({
              docType: "blog_post",
              lang: post.lang,
              uid: post.uid,
            })}
            className="text-body"
          >
            {post.data.title.text}
          </Link>
        </h2>
        <p className="mb-4">
          {post.data.body &&
            getTextFromSlices(post.data.body).substring(
              0,
              POST_PREVIEW_TEXT_LENGTH,
            )}
        </p>
        <Link
          to={
            post.uid &&
            getPath({
              docType: "blog_post",
              lang: post.lang,
              uid: post.uid,
            })
          }
          className="cta cta-blog"
        >
          {home.continue_reading_button}
        </Link>
      </div>
      <div className="col-md-6">
        <img src={post.data.cover.url} className="img-fluid" />
      </div>
    </div>
  )
}

export default FirstPost
