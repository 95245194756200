import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import BlogRoll from "../components/blog-roll"
import NavbarBlog from "../components/navbar-blog"
import SubscribeBlog from "../components/subscribe-blog"
import FirstPost from "../components/first-post"
import _ from "lodash"
import { withPreview } from "gatsby-source-prismic"
import { getPath } from "../utils/gatsby-node-helpers"

const blogHomeTitle = { "en-us": "Blog Home", "de-de": "Hauptseite" }
export const query = graphql`
  query BlogHomeQuery($lang: String) {
    prismicBlogHome(lang: { eq: $lang }) {
      data {
        continue_reading_button
        description_meta
        image_meta {
          url
        }
        keywords_meta
        subscribe_button
        subscribe_image {
          url
        }
        subscribe_text
        subscribe_title
        title_meta
      }
    }
    allPrismicBlogPost(
      filter: { lang: { eq: $lang } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      totalCount
      nodes {
        data {
          custom_tag_group {
            custom_tag {
              uid
              type
              lang
            }
          }
          category {
            uid
          }
          cover {
            url
          }
          title {
            text
          }
          title_meta
          body {
            ... on PrismicBlogPostBodyContentBlog {
              primary {
                content_blog {
                  html
                  raw
                  text
                }
              }
              slice_type
            }
          }
        }
        first_publication_date(locale: $lang)
        uid
        lang
      }
    }
    allPrismicCategory {
      nodes {
        data {
          category
        }
        uid
        lang
      }
    }
    allPrismicCustomTag {
      nodes {
        uid
        lang
        data {
          title {
            text
          }
        }
      }
    }
  }
`

const RenderBody = ({ posts, locale, home, tags, categories, tagsUids }) => {
  return (
    <Fragment>
      <NavbarBlog locale={locale} category={blogHomeTitle[locale]} />
      <main className="blog pt-6 pt-md-7">
        <div className="container">
          <FirstPost
            post={posts[0]}
            home={home}
            locale={posts[0]?.lang}
            categories={categories}
          />
          <BlogRoll
            posts={posts.slice(1)}
            locale={locale}
            tags={tags}
            tagsUids={tagsUids}
          />
          <div className="text-center py-3">
            <Link
              className="cta cta-blog"
              to={getPath({
                docType: "blog_posts_pagination",
                uid: "",
                lang: locale,
              })}
            >
              See all posts
            </Link>
          </div>
        </div>
      </main>
      <SubscribeBlog home={home} locale={locale} />
    </Fragment>
  )
}

const BlogHome = ({ data, pageContext, location }) => {
  const { lang: locale } = pageContext
  const home = data.prismicBlogHome.data
  const posts = data.allPrismicBlogPost.nodes
  const categories = data.allPrismicCategory.nodes
  const tags = data.allPrismicCustomTag.nodes
  let tagsUids = []

  posts.forEach(node => {
    const postTags = node.data.custom_tag_group
    postTags.forEach(tag => {
      const tagUid = tag.custom_tag?.uid
      if (tagUid) {
        tagsUids.push(tagUid)
      }
    })
  })
  tagsUids = _.uniq(tagsUids)

  if (!home || !posts) return null

  const { image_meta, keywords_meta, title_meta, description_meta } = home

  return (
    <Layout
      metadata={{
        title: title_meta,
        description: description_meta,
        keywords: keywords_meta,
        image: image_meta && image_meta.url,
      }}
      mainPage="companies"
      locale={locale}
      path={location && location.pathname}
    >
      <RenderBody
        home={home}
        posts={posts}
        locale={locale}
        tags={tags}
        tagsUids={tagsUids}
        categories={categories}
      />
    </Layout>
  )
}

export default withPreview(BlogHome)
